import { useEffect } from "react";
import { useGlobalContext } from "../../context/context";

const PrivacyPolicy = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Privacy Policy`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='privacy'>
        <div className='block'>
          <h2>Privacy Policy</h2>
          <p>
            As part of our daily business operations, we collect personal
            information from our clients and prospective clients in order to
            provide them with our products and services, and ensure that we can
            meet their needs when providing these products and services, as well
            Our Privacy Notice is reviewed regularly to ensure that any new
            obligations and technologies, as well as any changes to our business
            operations and practices are taken into consideration, as well as
            that it remains abreast of the changing regulatory environment. Any
            personal information we hold will be governed by our most recent
            Privacy Notice. <br />
            <br /> Please note that if you are an employee of the Company, a
            contractor to the Company or a third-party provider, your personal
            information will be used in connection with your employment contract
            or your contractual relationship, whichever applies. This Privacy
            Notice applies to the processing activities performed by
            Stashnetwork to the personal information of its clients and its
            potential clients and website visitors. <br />
            <br /> We may amend this Privacy Notice at any time by posting the
            amended version on this site including the effective date of the
            amended version. We will announce any material changes to this
            Privacy Notice on our website.
          </p>
        </div>
        <div className='block'>
          <h2>Definitions</h2>
          <p>
            As used herein, the following terms are defined as follows: <br />
            <br /> “Digital Asset” is a digital representation of value (also
            referred to as “cryptocurrency,” “virtual currency,” “digital
            currency,” “crypto token,” “crypto asset,” or “digital commodity”),
            such as bitcoin, XRP or ether, which is based on the cryptographic
            protocol of a computer network that may be (i) centralized or
            decentralized, (ii) closed or open-source, and (iii) used as a
            medium of exchange and/or store of value. <br />
            <br /> “We,” and “Us” refers to Stashnetwork. <br />
            <br /> “Personal Information” or “Personal Data” or “your data”
            refers to any information relating to you, as an identified or
            identifiable natural person, including your name, an identification
            number, location data, or an online identifier or to one or more
            factors specific to the physical, economic, cultural or social
            identity of you as a natural person.
          </p>
        </div>
        <div className='block'>
          <h2>How do we protect personal information?</h2>
          <p>
            The Company respects the privacy of any users who access its
            website, and it is therefore committed to taking all reasonable
            steps to safeguard any existing or prospective clients, applicants
            and website visitors. The Company keeps any personal data of its
            clients and its potential clients in accordance with the applicable
            privacy and data protection laws and regulations. <br />
            <br /> We have the necessary and appropriate technical and
            organisational measures and procedures in place to ensure that your
            information remains secure at all times. We regularly train and
            raise awareness for all our employees to the importance of
            maintaining, safeguarding and respecting your personal information
            and privacy. We regard breaches of individuals&apos; privacy very
            seriously and will impose appropriate disciplinary measures,
            including dismissal from employment. We have also appointed a Group
            Data Protection Officer, to ensure that our Company manages and
            processes your personal information in compliance with the
            applicable privacy and data protection laws and regulations, and in
            accordance with this Privacy Notice. <br />
            <br /> The personal information that you provide us with when
            applying to open an account, applying for a role within the Company,
            or when using our website, is classified as registered information,
            which is protected in several ways. You can access your registered
            information after logging in to your account by entering your
            username and the password that you have selected. It is your
            responsibility to make sure that your password is only known to you
            and not disclosed to anyone else. Registered information is securely
            stored in a safe location, and only authorised personnel have access
            to it via a username and password. All personal information is
            transferred to the Company over a secure connection, and thus all
            reasonable measures are taken to prevent unauthorised parties from
            viewing any such information. Personal information provided to the
            Company that does not classify as registered information is also
            kept in a safe environment and accessible by authorised personnel
            only through username and password.
          </p>
        </div>
        <div className='block'>
          <h2>Information we may collect about you</h2>
          <p>
            In order to open an account with us, you must first complete and
            submit a “create account” form to us by completing the required
            information. By completing this form, you are requested to disclose
            personal information in order to enable the Company to assess your
            application and comply with the relevant laws (including their
            regulations). <br />
            <br /> The information that we collect from you is as follows:{" "}
            <br />
            <br /> Full name, residential address and contact details (e.g.
            email address, telephone number, fax etc.); <br />
            <br /> Date of birth, place of birth, gender, citizenship; Bank
            account information, credit card details, including details about
            your source of funds, assets and liabilities, and OFAC information;{" "}
            <br />
            <br /> Trading account balances, trading activity, your inquiries
            and our responses; <br />
            <br /> Information on whether you hold a prominent public function
            (PEP); <br />
            <br /> Verification information, which includes information
            necessary to verify your identity such as a passport, driver&apos;s
            licence or Government-issued identity card; <br />
            <br /> Other Personal Information or commercial and/or
            identification information - Whatever information we, in our sole
            discretion, deem necessary to comply with our legal obligations
            under various anti-money laundering (AML) obligations, such as under
            the European Union&apos;s 4th AML Directive and the U.S. Bank
            Secrecy Act (BSA).
          </p>
        </div>
        <div className='block'>
          <h2>Where we store your personal data</h2>
          <p>
            Our operations are supported by a network of computers, servers, and
            other infrastructure and information technology, including, but not
            limited to, third-party service providers. We and our third-party
            service providers and business partners store and process your
            personal data in the European Union, Japan, the United Kingdom, the
            United States of America and elsewhere in the world.
          </p>
        </div>
        <div className='block'>
          <h2>
            Transfers of personal information outside of the European Economic
            Area (EEA) and the United Kingdom (UK)
          </h2>
          <p>
            We may transfer your personal information outside the EEA and UK to
            other Company subsidiaries, service providers and business partners
            (i.e Data Processors) who are engaged on our behalf. To the extent
            that we transfer your personal information outside of the EEA and
            UK, we will ensure that the transfer is lawful and that Data
            Processors in third countries are obliged to comply with the
            European Union (EU) General Data Protection Act 2016 and the UK Data
            Protection Act 2018. If transfers of personal information are
            processed in the US, we may in some cases rely on standard
            contractual clauses.
          </p>
        </div>
        <div className='block'>
          <h2>Transfers of Personal Information outside of your country</h2>
          <p>
            By using our products and services, you consent to your Personal
            Data being transferred to other countries, including countries that
            have differing levels of privacy and data protection laws than your
            country. In all such transfers, we will protect your personal
            information as described in this Privacy Notice, and ensure that
            appropriate information sharing contractual agreements are in place.
          </p>
        </div>
        <div className='block'>
          <h2>Privacy when using digital assets and blockchains</h2>
          <p>
            Your funding of bitcoin, XRP, ether, and other Digital Assets, may
            be recorded on a public blockchain. Public blockchains are
            distributed ledgers, intended to immutably record transactions
            across wide networks of computer systems. Many blockchains are open
            to forensic analysis which can lead to deanonymization and the
            unintentional revelation of private financial information,
            especially when blockchain data is combined with other data. <br />
            <br /> Because blockchains are decentralized or third-party networks
            which are not controlled or operated by Payward or its affiliates,
            we are not able to erase, modify, or alter personal data from such
            network
          </p>
        </div>
        <div className='block'>
          <h2>Data Retention</h2>
          <p>
            Safeguarding the privacy of your personal information is of utmost
            importance to us, whether you interact with us personally, by phone,
            by email, over the internet or any other electronic medium. We will
            hold personal information, for as long as we have a business
            relationship with you, in secure computer storage facilities, and we
            take the necessary measures to protect the personal information we
            hold from misuse, loss, unauthorised access, modification or
            disclosure. <br />
            <br /> When we consider that personal information is no longer
            necessary for the purpose for which it was collected, we will remove
            any details that will identify you or we will securely destroy the
            records. However, we may need to maintain records for a significant
            period of time (after you cease being our client). For example, we
            are subject to certain anti-money laundering laws which require us
            to retain the following, for a period of 5 years after our business
            relationship with you has ended. <br />
            <br /> Also, the personal information we hold in the form of a
            recorded information, by telephone, electronically or otherwise,
            will be held in line with local regulatory requirements (i.e. 5
            years after our business relationship with you has ended or longer
            if you have legitimate interests (such as handling a dispute with
            you)). If you have opted out of receiving marketing communications
            we will hold your details on our suppression list so that we know
            you do not want to receive these communications.
          </p>
        </div>
        <div className='block'>
          <h2>Cookies</h2>
          <p>
            When you use our products and services, we may make use of the
            standard practice of placing tiny data files called cookies, flash
            cookies, pixel tags, or other tracking tools (herein, “Cookies”) on
            your computer or other devices used when engaging with us. We use
            Cookies to (i) help us recognize you as a customer, collect
            information about your use of our products and services, to better
            customize our services and content for you, and to collect
            information about your computer or other access devices to ensure
            our compliance with our BSA and AML obligations.
          </p>
        </div>
        <div className='block'>
          <h2>Changes to this Privacy Notice</h2>
          <p>
            Our Privacy Notice is reviewed regularly to ensure that any new
            obligations and technologies, as well as any changes to our business
            operations and practices are taken into consideration, as well as
            that it remains abreast of the changing regulatory environment. Any
            personal information we hold will be governed by our most recent
            Privacy Notice. <br />
            <br /> If we decide to change our Privacy Notice, we will post those
            changes to this Privacy Notice and other places we deem appropriate.
          </p>
        </div>
        <div className='block'>
          <h2>Our products and services are not available to children</h2>
          <p>
            Our products and services are not directed to persons under the age
            of 18, hereinafter “Children”, “Child” and we do not knowingly
            collect personal information from Children. If we learn that we have
            inadvertently gathered personal information from a Child, we will
            take legally permissible measures to remove that information from
            our records. The Company will require the user to close his or her
            account and will not allow the use of our products and services. If
            you are a parent or guardian of a Child, and you become aware that a
            Child has provided personal information to us, please contact us at
            support@stashnetwork.org and you may request to exercise your
            applicable access, rectification, cancellation, and/or objection
            rights.
          </p>
        </div>
        <div className='block'>
          <h2>Contact Information</h2>
          <p>
            Any questions, complaints, comments and requests regarding this
            Privacy Notice are welcome and should be addressed to
            support@stashnetwork.org
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
