import React from "react";

function VerifyAccount() {
  return (
    <article className='verify-account'>
      <div className='modal'>
        <h2>Account Verification</h2>
        <p>
          Please send a copy of your government-issued ID to our support email
          to complete your account verification process. Make sure to include
          your username in the subject line. Thank you for your cooperation.
        </p>
        <br />
        <a href='mailto: support@stashnetwork.org?subject=Account Verification'>
          <button className='gold'>Upload ID</button>
        </a>
      </div>
    </article>
  );
}

export default VerifyAccount;
